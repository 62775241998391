@font-face { font-family: SigmarOne-Regular; 
  src: url('./SigmarOne-Regular.ttf'); } 

@font-face { font-family: LobsterTwo-Regular; 
  src: url('./LobsterTwo-Regular.ttf'); } 

@font-face { font-family: LobsterTwo-Bold; 
  src: url('./LobsterTwo-Bold.ttf'); } 
  

div {
  margin: 0;
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  background-color: #e93423;
}

a:link {
  color: #000;
  text-decoration: none;
}

a:visited {
  color: #000;
  text-decoration: none;
}

p {
  padding-left: 10%;
  padding-right: 10%;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 3rem;
  font-family: LobsterTwo-Bold;
  color: #000;
}

.header h2 {
  padding-bottom: 0;
  text-shadow: 1px 1px 2px #fff;
}

h4 {
  font-family: sans-serif;
  font-size: 1.5rem;
  text-align: center;
  padding-left: 300px;
  padding-right: 300px;
  font-weight: lighter;
  color: #fff;
}

.header p {
  font-size: 2rem;
  color: #000;
  text-align: center;
}

.productline {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: space-around;
  padding-right: 8%;
  margin: 0;
}

.product {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2%;
  text-align: center;
}

.head {
  display: flex;
  flex-direction: column;
  gap: 0;
  align-items: center;
  font-size: 3rem;
  font-family: LobsterTwo-Bold;
  text-shadow: 2px 2px 4px #fff;
}

.product p {
  font-size: 2rem;
  padding-bottom: 4%;
  color: #fff;
}

.product h3 {
  font-size: 2.5rem;
  padding-bottom: 2%;
}

.product button {
  align-items: center;
  font-size: 2rem;
  font-family: LobsterTwo-Regular;
  background-color: greenyellow;
  color: #fff;
  text-shadow: 1px 1px 2px #000;
  border: 1px 1px 1px 1px #000;
}

.prodimg {
  padding-left: 6%;
  padding-bottom: 5%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@media only screen and (max-device-width: 450px) {
  .div {
    padding-bottom: 10%;
  }

  .header p {
    font-size: 1.25rem;
    padding-left: 20%;
    padding-right: 20%;
  }

  h4 {
    font-size: 1rem;
    padding-left: 25%;
    padding-right: 25%;
  }
  
  .headimg {
    width: 320px;
    height: 60px;
    padding-top: 5%;
  }

  .productline {
    flex-direction: column;
    padding-bottom: 10%;
    padding-left: 5%;
  }

  .prodimg {
    width: 300px;
    height: 300px;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }

}
